var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"doShow",staticStyle:{"padding-top":"20px","margin-right":"5%","margin-left":"5%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 9}},[_c('v-slide-group',{attrs:{"show-arrows":""}},[_c('v-slide-item',[_c('v-btn',{staticStyle:{"margin":"10px"},attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown,"color":_vm.selectedPage != 0 ? 'notSelected' : 'projectsBtn',"outlined":""},on:{"click":function($event){return _vm.selectPage(0)}}},[_vm._v(" ALL ")])],1),_vm._l((_vm.languages),function(language,i){return _c('v-slide-item',{key:i},[_c('v-btn',{staticStyle:{"margin":"10px"},attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown,"color":_vm.selectedPage != i+1 ? 'notSelected' : 'projectsBtn',"outlined":""},on:{"click":function($event){return _vm.selectPage(i+1)}}},[_vm._v(" "+_vm._s(language)+" ")])],1)})],2)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 3}},[_c('v-text-field',{attrs:{"label":"Search","outlined":"","clearable":"","append-icon":"mdi-magnify"},on:{"input":_vm.searchProjects},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.showProjects.length > 0)?_c('v-row',{staticClass:"doShow",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticStyle:{"padding":"0","margin":"0"},attrs:{"cols":"12"}},[_c('v-data-iterator',{staticStyle:{"padding":"0","margin":"0"},attrs:{"sort-by":"date","sort-desc":true,"items-per-page":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 25,"items":_vm.showProjects,"page":_vm.page,"footer-props":{
                            itemsPerPageText: _vm.$vuetify.breakpoint.smAndDown ? 'Show' : 'Projects per page',
                            itemsPerPageOptions: _vm.$vuetify.breakpoint.mdAndDown ?  [12,24,-1] : [25, 50, -1],
                            showCurrentPage: true,
                            showFirstLastPage: _vm.$vuetify.breakpoint.smAndDown ? false : true,
                            disableItemsPerPage: _vm.$vuetify.breakpoint.smAndDown,
                        }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-container',{staticStyle:{"padding-bottom":"40px","margin":"0"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"margin-left":"10%","margin-right":"10%"},attrs:{"justify":"center"}},_vm._l((props.items),function(item,i){return _c('v-col',{key:i,staticStyle:{"max-width":"260px"},attrs:{"cols":"auto","md":"4"}},[_c('projectView',{staticClass:"doShow",staticStyle:{"height":"100%"},attrs:{"project":item}})],1)}),1)],1)]}}],null,false,1148493159)})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }